import {ModuleWithProviders, NgModule} from '@angular/core';
import {ClickOutsideModule} from 'ng-click-outside';
import {NgSelectModule} from '@ng-select/ng-select';
import {SelectAutocompleteComponent} from './components/select-autocomplete/select-autocomplete.component';
import {B64imgDirective} from './directives/b64img.directive';
import {ViewContainerDirective} from './directives/view-container.directive';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {ThemeModule} from '../../@theme/theme.module';
import {MonthYearSelectComponent} from './components/month-year-select/month-year-select.component';
import {ConfirmModule} from '../@portal-shared/confirm/confirm.module';
import {FloorPlanComponent} from './components/floor-plan/floor-plan.component';
import {RangeFilteredTable} from './components/range-filtered-table/range-filtered-table.component';
import {CheckFacilityModule} from '../@portal-shared/check-facility/check-facility.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {FloorPlanViewOptionsComponent} from './components/floor-plan/floor-plan-view-options.component';
import {FacilitySelectAddonComponent} from './components/facility-select-addon/facility-select-addon.component';
import {LoginModalComponent} from './components/login-modal/login-modal.component';
import {IframeResizerDirective} from './directives/iframe-resizer-directive';
import {ReviewIndicationComponent} from './components/review-indication/review-indication.component';
import {HoverActionIconsComponent} from '../@portal-shared/hover-components/components/hover-action-icons/hover-action-icons.component';
import {PreviewComponent} from '../@portal-shared/hover-components/components/preview/preview.component';
import {SmartLinkComponent} from '../@portal-shared/hover-components/smart-link/smart-link.component';
import {HoverComponentDirective} from '../@portal-shared/hover-components/directives/hover-component.directive';
import {HoverActionsDirective} from '../@portal-shared/hover-components/directives/hover-actions.directive';
import {HoverPatientActionsDirective} from '../@portal-shared/hover-components/directives/hover-patient-actions.directive';
import {TableRowComponent} from './components/table/table-row/table-row.component';
import {TableHeaderComponent} from './components/table/table-header/table-header.component';
import {PaginatedTableComponent} from './components/paginated-table/paginated-table.component';
import {PaginatedTableDetailsComponent} from './components/paginated-table-details/paginated-table-details.component';
import {PreviewService} from '../@portal-shared/hover-components/services/preview.service';
import {NewVersionAvailableModalComponent} from './components/new-version-available-modal/new-version-available-modal.component';
import {ComboInputComponent} from './components/combo-input/combo-input.component';
import {PayerFilterComponent} from './components/payer-filter/payer-filter.component';
import {HoverPreviewDirective} from '../@portal-shared/hover-components/directives/hover-preview.directive';
import {PhysicianSelectComponent} from './components/physician-select/physician-select.component';
import {NavigationModule} from '../@portal-shared/navigation/navigation.module';
import {FileSelectComponent} from './components/file-select/file-select.component';
import {FileSelectSectionComponent} from './components/file-select/file-select-section/file-select-section.component';
import {FileSelectDirective} from './components/file-select/file-select.directive';
import {FacilityPipe} from '../../@theme/pipes/facility.pipe';
import {PhysicianTeamSelectComponent} from './components/physician-team-select/physician-team-select.component';
import {OrganizationPipe} from '../../@theme/pipes/organization.pipe';
import {IframeComponent} from './components/iframe/iframe.component';
import {PatientSelectComponent} from './components/patient-select/patient-select.component';
import {NoteStatusIndicationComponent} from './components/note-status-indication/note-status-indication.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {DepGrpUsrChooserComponent} from './components/dep-grp-usr-chooser/dep-grp-usr-chooser.component';
import {ListPreviewComponent} from './components/list-preview/list-preview.component';
import {ChangeComponent} from './components/change/change.component';
import {AddPlaceholderComponent} from './components/add-placeholder/add-placeholder.component';
import {NoContentComponent} from './components/no-content/no-content.component';
import {SmartTableComponent} from './components/table/smart-table.component';
import {CalendarEventLabelComponent} from './components/calendar-event-label/calendar-event-label.component';
import {ImportStatusBadgeComponent} from '../@portal-shared/import-status-badge/import-status-badge.component';
import {EmbedComponent} from './components/embed/embed.component';
import {TwoFactorAuthComponent} from './components/two-factor-auth/two-factor-auth.component';
import {PageHeaderModule} from './page-header/page-header.module';
import {BreadcrumbsModule} from './components/breadcrumbs/breadcrumbs.module';
import {PatientStatusSelectComponent} from './components/patient-status-filter/patient-status-select.component';
import {RequestHandlerDirective} from '../@portal-shared/request-handler/request-handler.directive';
import {LatestLabResultsModule} from './components/latest-lab-results/latest-lab-results.module';
import {SelfGenerateTableComponent} from './components/self-generate-table/self-generate-table.component';
import {AdvancedInputModule} from './components/advanced-input/advanced-input.module';
import {DatepickerSelectModule} from './components/datepicker/datepicker-select.module';
import {BstBadgeComponent} from './components/bst-badge/bst-badge.component';
import {RecaptchaSolverComponent} from './components/recaptcha-solver/recaptcha-solver.component';
import {EntrySourceBadgeComponentComponent} from './components/entry-source-badge-component/entry-source-badge-component.component';
import {BstViewSelectComponent} from './components/bst-view-select/bst-view-select.component';
import {DragAndDropContainerComponent} from './components/file-select/drag-and-drop-container/drag-and-drop-container.component';
import {ButtonToastComponent} from './components/button-toast/button-toast.component';
import {StatusLabelComponent} from './components/status-label/status-label.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';

const BASE_MODULES = [
    ThemeModule,
    ClickOutsideModule,
    NgSelectModule,
    DragAndDropModule,
    NgxPermissionsModule,
    BreadcrumbsModule,
    LatestLabResultsModule,
];

const MODULES = [
    ConfirmModule,
    CheckFacilityModule,
    NavigationModule,
    PageHeaderModule,
    AdvancedInputModule,
    DatepickerSelectModule,
];

const COMPONENTS = [
    BstBadgeComponent,
    PaginationComponent,
    DepGrpUsrChooserComponent,
    SelectAutocompleteComponent,
    ListPreviewComponent,
    ChangeComponent,
    AddPlaceholderComponent,
    NoContentComponent,
    TableHeaderComponent,
    TableRowComponent,
    SmartTableComponent,
    PaginatedTableComponent,
    PaginatedTableDetailsComponent,
    MonthYearSelectComponent,
    RangeFilteredTable,
    FloorPlanComponent,
    FloorPlanViewOptionsComponent,
    FacilitySelectAddonComponent,
    ReviewIndicationComponent,
    SmartLinkComponent,
    ComboInputComponent,
    PayerFilterComponent,
    PhysicianSelectComponent,
    FileSelectComponent,
    FileSelectSectionComponent,
    PatientSelectComponent,
    PhysicianTeamSelectComponent,
    CalendarEventLabelComponent,
    NoteStatusIndicationComponent,
    ImportStatusBadgeComponent,
    TwoFactorAuthComponent,
    PatientStatusSelectComponent,
    SelfGenerateTableComponent,
    RecaptchaSolverComponent,
    EntrySourceBadgeComponentComponent,
    BstViewSelectComponent,
    DragAndDropContainerComponent,
    ButtonToastComponent,
    StatusLabelComponent,
    ProgressBarComponent,
];

const ENTRY_COMPONENTS = [
    LoginModalComponent,
    NewVersionAvailableModalComponent,
    HoverActionIconsComponent,
    PreviewComponent,
    IframeComponent,
    EmbedComponent,
];

const DIRECTIVES = [
    B64imgDirective,
    ViewContainerDirective,
    IframeResizerDirective,
    HoverComponentDirective,
    HoverPreviewDirective,
    HoverActionsDirective,
    HoverPatientActionsDirective,
    FileSelectDirective,
    RequestHandlerDirective,
];

const PIPES = [
    FacilityPipe,
    OrganizationPipe,
];

const THEME_PROVIDERS = [];

@NgModule({
    imports: [
        ...BASE_MODULES,
        ...MODULES,
    ],
    declarations: [
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
    ],
    providers: [
        PreviewService,
    ],
    exports: [
        ...BASE_MODULES,
        ...MODULES,
        ...COMPONENTS,
        ...ENTRY_COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
    ],
})
export class PortalThemeModule {
    static forRoot(): ModuleWithProviders<PortalThemeModule> {
        return {
            ngModule: PortalThemeModule,
            providers: [...THEME_PROVIDERS],
        };
    }
}
