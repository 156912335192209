<div *ngIf="progress || progress === 0"
     class="progress"
     style="min-width: 50px; max-width: 120px;">
    <div class="progress-bar"
         [style.width]="progress * 100 + '%'"
         [style.background-color]="color"
         [ngClass]="colorClass ? 'bg-' + colorClass : null">
    </div>
    <div class="position-absolute w-100 h-100 text-center text-body">
        {{progress * 100|number:'1.0-1'}}%
    </div>
</div>
